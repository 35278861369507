.hero {
    /* background-color: var(--overlay); */
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
}

.hero:before {
    content: '';
    background: url('../images/pic1.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero .content {
    height: 100%;
    border: 10px;
    border-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin: auto;
    padding: 1rem;
}

.hero .content p {
    font-style: roboto;
    font-size: 5rem;
    font-weight: 700;
}
.hero .content span {
    color: #CBAD4C;
}

.hero .button {
    margin: 1rem 0;
    width: 300px;
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    color: #333;
}

.hero .button:hover {
    background-color: var(--secondary-dark);
    border: 1px solid var(--secondary-dark);
}

@media screen and (max-width:940px) {
    .hero .content p {
        font-size: 3.5rem;
    }
    .hero:before {
        content: '';
        background: url('../images/pic1.jpg') no-repeat right center/cover;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

}